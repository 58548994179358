import React from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'
import {MdOutlineVerifiedUser} from 'react-icons/md'
import {TbCertificate} from 'react-icons/tb'
import {BiDollar} from 'react-icons/bi'
import {AiFillLock} from 'react-icons/ai'

const Footer = () => {
return (
    <footer className='footer'>
        <div className='footer-container'>
            <div className='mention-legale'>
                <div className='title'>
                    <h4>Mentions légales</h4>
                </div>
                <div className='link-area'>
                    <Link to = "/mention-legal">Mentions légales</Link>
                </div>
            </div>
            
            <div className='service-client'>
                <div className='title'>
                    <h4>Service Client</h4>
                </div>
                <span>Pour tous renseignements</span>
                <span>astroliv7@gmail.com</span>
            </div>
            <div className='horaire-douverture'>
                <div className='title'>
                    <h4>Horaires d'ouverture</h4>
                </div>
                <p>Tchat ouvert 7j / 7 Lundi / Jeudi : 8h00 à 00h00 Mardi / Mercredi et Dimanche : 8h00 à 00h30 Samedi : 8h00 à 23h00</p>
            </div>
        </div>
        <div className='badge-container'>
            <div className='badge-item'>
                <MdOutlineVerifiedUser size='50' color='#13212e'/>
                <p>Garantie anonymat <br /> Protection des données personnelles</p>
            </div>
            <div className='badge-item'>
                <TbCertificate size='50' color='#13212e'/>
                <p>Voyants experts <br /> Grâce à plusieurs années d'expérience</p>
            </div>
            <div className='badge-item'>
                <BiDollar size='50' color='#13212e'/>
                <p>Prix adaptatifs <br />Des formules adaptées à vos besoins</p>
            </div>
            <div className='badge-item'>
                <AiFillLock size='50' color='#13212e'/>
                <p>Paiement sécurisé</p>
            </div>
        </div>
    </footer>
)
}

export default Footer