import './CreateAcountPopUp.css'
import React, { useState } from 'react'
import Axios from 'axios'
import {BASE_URL }from '../../configApi/apiConfig'
const CreateAcountPopUp = (props) => {
    const [modal, setModal] = useState(false)
    const [prenom, setPrenom] = useState("")
    const [pseudo, setPseudo] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [exist, setExist] = useState(true)
    const postData = async (e) => {
        e.preventDefault();
        if (exist) {
            try {
                await Axios.post(`${BASE_URL}/api/Utilisateur_create/`, {
                    prenom,
                    pseudo,
                    email,
                    password,
                });
            } catch (err) {
                console.log(err);
            } finally {
                toggleModal();
                setExist(false);
            }
        }
    };
    const checkPseudoExistence = async (value) => {
        try {
            const response = await Axios.post(`${BASE_URL}/api/pseudo_utilisateur_bd/`, {
                pseudo: value
            });
            console.log(response.data);
            setExist(response.data);
        } catch (err) {
            console.log(err);
        }
    };
    const toggleModal = () => {
        setModal(!modal)
    }
    if (modal) {
        document.body.classList.add('active-modal')
        window.scroll(0, 0)
    } else {
        document.body.classList.remove('active-modal')
    }
    return (
        <>
            <button type='button' onClick={toggleModal} className="btn-header">
                {props.title}
            </button>
            {modal && (
                <div className='.modal'>
                    <div className='overlay' onClick={toggleModal}></div>
                    <div className='modal-content'>
                        <form onSubmit={postData}>
                            <h3 className='modal-h2'>Créer un compte</h3>
                            <div className="formBox">
                                <div className="inputBox w50">
                                    <input type="text" name="" required value={prenom} onChange={(e) => setPrenom(e.target.value)} />
                                    <span>Prénom</span>
                                </div>
                                <div className={exist ? "verifier-pseudo inputBox w50 not-exist" : "verifier-pseudo inputBox w50 exist"}>
                                <input
                                        type="text"
                                        name=""
                                        required
                                        value={pseudo}
                                        onChange={async (e) => {
                                            setPseudo(e.target.value);
                                            await checkPseudoExistence(e.target.value);
                                        }}
                                    />
                                    <span>Pseudo</span>
                                </div>
                                <div className="inputBox w100">
                                    <input type="text" name="" required value={email} onChange={(e) => setEmail(e.target.value)} />
                                    <span>E-mail</span>
                                </div>
                                <div className="inputBox w50">
                                    <input type="password" name="" required value={password} onChange={(e) => setPassword(e.target.value)} />
                                    <span>Mot de passe</span>
                                </div>
                                <div className="inputBox w100">
                                    <button className='submit-btn' type="submit">Créer</button>
                                </div>
                            </div>
                        </form>
                        <button className='close-modal' onClick={toggleModal}>
                            Fermer
                        </button>
                    </div>
                </div>
            )}
        </>
    )
}

export default CreateAcountPopUp