import React from 'react'
import './MentionsLegal.css'
import {Navbar , Container} from "../index"
import { Footer } from '../../Sections'

const MentionLegal = () => {
return (
    <>
        <Navbar />
        <Container>
            <div className='mention-legal'>
                <div className='mention-legal-menu'>
                    <h4>Menu</h4>
                    <a href='#description-service'>Description des Services</a>
                    <a href="#confi">Politique de confidentialité</a>
                    <a href='#retour'>Politique de retour</a>
                </div>
                <div className='mention-legal-content'>
                    <section id='description-service'>
                        <p>
                        En utilisant notre site Astroliv spécialisé dans la voyance par chat, vous acceptez les présentes
                        Conditions Générales d'Utilisation (CGU). Veuillez les lire attentivement avant d'accéder au site
                        ou d'utiliser nos services de voyance par chat. Si vous n'acceptez pas ces CGU, veuillez ne pas
                        utiliser le site.<br />
                        </p>
                        <h5>Description des Services</h5>
                        <p>
                        AstroLiv est un site exploité par la societé GAYOUMONEY LTD, qui offre des consultations de
                        voyance réalisées par des voyants professionnels via un service de chat en ligne. Les voyants
                        sont des prestataires indépendants et le site agit uniquement en tant qu'intermédiaire pour
                        faciliter les consultations.<br />
                        </p>
                        <h5>Utilisation du Site</h5>
                        <p>
                        <strong >2.1.</strong> Conditions d'Âge : Vous devez avoir au moins 18 ans pour utiliser notre site et nos
                        services de voyance par chat. <br /> <br/>
                        <strong>2.2. </strong> L’utilisateur définit lui-même le périmètre de sa vie privée qu’il souhaite divulguer au
                        Consultant et renseigne uniquement les informations le concernant. <br /> <br />
                        <strong>2.3.</strong> L’utilisateur est seul responsable des informations ou contenus qu’il renseigne ou édite <br/> <br/>
                        <strong>2.4.</strong> Conformité aux Lois : Vous vous engagez à utiliser le site et les services conformément à
                        toutes les lois applicables en vigueur. <br/> <br/>
                        <strong>2.5.</strong> Respect des Autres Utilisateurs : Vous acceptez de respecter les autres utilisateurs du
                        site, y compris les voyants, en évitant tout langage offensant, diffamatoire, ou toute autre
                        conduite inappropriée lors des sessions de chat. <br /> <br/>
                        <strong>2.6.</strong> La diffusion, sous quelque forme que ce soit, des informations ou contenus intégrant des
                        liens vers des sites tiers qui auraient un caractère illégal et/ou contraires aux bonnes mœurs est
                        expressément prohibée. <br /> <br/>
                        <strong>2.7.</strong> L’utilisateur s’engage à ne pas diffuser le contenu des échanges et messages
                        (enregistrement ou retranscription écrite) qui leur ont été directement adressés dans le cadre
                        des Services. <br /> <br/>
                        <strong>2.8. </strong> L’utilisateur s’engage à ne pas utiliser le site Internet ou les Services dans le but
                        d’organiser des rassemblements ou des manifestations. <br /> <br/>
                        En cas de non-respect par l’utilisateur de l’une des présentes dispositions des CGU, le
                        Prestataire se réserve le droit de supprimer le propos ou le contenu litigieux, de supprimer
                        immédiatement le Compte utilisateur et d’engager toute action judiciaire qui s’avérerait
                        nécessaire. <br/>
                        La suppression d’un Compte utilisateur n’autorise en aucune façon la création d’un nouveau
                        Compte utilisateur
                        </p>
                        <h5>Inscription et Compte Utilisateur</h5>
                        <p>
                        <strong>3.1.</strong> Création de Compte : Pour accéder aux services de voyance par chat, vous devrez créer un
                        compte utilisateur en fournissant des informations exactes et à jour. Tels que: <br/> <br />
                        – <strong >Pseudonyme</strong> <br/>
                        – <strong>Adresse e-mail</strong> <br/>
                        – <strong>Mot de passe pour se connecter au site Internet</strong> <br/>
                        – <strong>Numéro de téléphone</strong> <br/>
                        – <strong>Informations de sa carte bancaire</strong> <br/>
                        – <strong>Nom, prénom, date de naissance</strong> <br/>
                        – <strong>Adresse du domicile</strong> <br/> <br/>
                        Ces informations pourront être modifiées à tout moment par l’utilisateur. <br/> <br/>
                        <strong>3.2.</strong> Confidentialité des Données : Nous nous engageons à protéger vos données
                        personnelles conformément à notre Politique de Confidentialité. En créant un compte, vous
                        acceptez nos pratiques en matière de confidentialité.
                        </p>
                        <h5>Services de Voyance</h5>
                        <p>
                        <strong>4.1.</strong> Disponibilité des Voyants : Les voyants ont des horaires de disponibilité variables, et le site
                        ne peut garantir la disponibilité immédiate d'un voyant spécifique. <br/> <br />
                        <strong>4.2.</strong> Précision des Prédictions : Le site ne peut garantir la précision ou l'exactitude des
                        prédictions faites par les voyants lors des consultations. La voyance est un service basé sur des
                        perceptions intuitives, et les résultats peuvent varier. <br /> <br />
                        <strong>4.3.</strong> Responsabilités du voyant: Le voyant à pour responsabilité de <br />
                        - Communiquer avec tact, sérieux et prudence dans l’optique d’éclairer l’utilisateur <br />
                        - Respecter la plus grande confidentialité sur les informations confiées par l’utilisateur <br />
                        - Ne pas faire de prédictions concernant la santé, le décès <br />
                        - N’effectuer aucun travail occulte (désenvoûtement, sorcellerie, magie noire) <br />
                        - Fournir aux utilisateurs à fournir des Service conforme aux règles de l’art et à la législation. <br />
                        </p>
                        <h5>Tarifs et Paiements</h5>
                        <p>
                            <strong>5.1.</strong> Tarifs des Consultations : Les tarifs des consultations de voyance par chat sont clairement
                            indiqués sur notre site. Les prix peuvent varier en fonction des voyants et des types de
                            consultations. <br/>
                            Offre découverte: 5 min gratuie <br/>
                            <strong>Standard 05 min ---- 14.90</strong> <br />
                            <strong>Standard 10 min ---- 19.90€</strong> <br />
                            <strong>Standard 15 min ---- 29.90€</strong> <br />
                            <strong>Premium 30 min ---- 49.90€</strong> <br />
                            <strong>Premium 60 min ---- 89.90€</strong> <br />
                            <strong>Premium 120 min ---- 159.90€</strong> <br /> <br/>
                            L'offre découverte n'est valable qu'une fois par compte d'utilisateur <br /> <br />
                            L'utilisateur pourra uniquement choisir de recharger ou non son compte utilisateur au terme de
                            l'offre découverte de minutes supplémentaires. Le Prestataire propose donc au terme de l'offre
                            de découverte différents tarifs de recharges correspondant à un nombre de minutes de Tchat <br /> <br />
                            <strong>5.2. Paiements</strong>  <br /> <br />
                            <strong>5.2.1.</strong> Les paiements pour les services de voyance sont effectués en ligne via les cartes
                            bancaires. <br/> <br />
                            <strong>5.2.2.</strong> Préalablement à l’achat d’une consultation, l’utilisateur reconnaît être informé des
                            modalités de calcul du prix, indiqué à la minute. <br /> <br />
                            <strong>5.2.3.</strong> Il appartient à l’utilisateur de veiller à la durée de la consultation dont il bénéficie <br /> <br />
                            <strong>5.2.4. </strong> Le paiement des Services par l’utilisateur s’opèrera par un service de paiement sécurisé  <br /> <br />
                            <strong >5.2.5. </strong> Si l’utilisateur n’est pas le porteur de la carte bancaire, il confirme avoir d’ores et déjà
                            obtenu l’accord du porteur pour le paiement des Services qui seront consommés  <br /> <br />
                            <strong>5.2.6. </strong> L’utilisateur peut modifier ses coordonnées bancaires en se rendant sur sa rubrique « Mon
                            compte».  <br /> <br />
                        </p>
                    </section>
                    <section id='confi'>
                        <h5>Propriété Intellectuelle</h5>
                        <p>
                            <strong>6.1.</strong> Contenu du Site : Le contenu du site, y compris les textes, images, logos et autres
                            éléments, sont la propriété du site selon l'article L113-1 du code de la Propriété Intellectuelle. <br /> <br />
                            <strong>6.2. </strong> Utilisation du Contenu : Vous ne pouvez pas copier, reproduire, distribuer ou utiliser le
                            contenu du site sans notre autorisation expresse. <br /> <br />
                            <strong>6.2.3.</strong> Le Prestataire est soucieux du respect de la vie privée des utilisateurs et de la protection
                            des informations qui sont transmises et respecte la législation et la réglementation en vigueur en
                            matière de protection de la vie privée. <br /> <br />
                            <strong>6.2.4.</strong> L’utilisateur peut également, pour des motifs légitimes, s’opposer au traitement des
                            données le concernant <br /> <br />
                            <strong>6.2.5.</strong> Les données personnelles collectées ne sont pas commercialisées par le Prestataire. <br /> <br />
                            <strong>6.2.6.</strong> Les coordonnées bancaires ou de moyen de paiement permettant l’achat de Services sont 
                            recueillies et conservées de manière sécurisée par le Prestataire. <br /> <br />
                            <strong>6.2.7.</strong> L’utilisateur accepte que ses données personnelles recueillies lors de son inscription
                            soient transmises au Consultant. L’adresse e-mail, le mot de passe pour se connecter au site
                            Internet, le numéro de téléphone, les informations de sa carte bancaire, l’adresse du domicile ne
                            sont pas accessibles aux Consultants <br /> <br />
                            <strong>6.2.8.</strong> Les données personnelles de l’utilisateur peuvent être communiquées par le Prestataire sur
                            réquisition judiciaire des autorités judiciaires, policières ou administratives sans que l’utilisateur
                            ne puisse s’y opposer. <br /> <br />
                            <strong>6.2.9.</strong> Les droits d’utilisation concédés aux utilisateurs pour le monde entier sont réservés
                            exclusivement à un usage privé et personnel dans le cadre de l’utilisation des Services du site
                            Internet <br />
                        </p>
                    </section>
                    <section id='retour'>
                        <h5>Modification des CGU</h5>
                            <p>
                                Nous nous réservons le droit de modifier ces CGU à tout moment. Les modifications prendront
                                effet dès leur publication sur le site. Il est de votre responsabilité de vérifier périodiquement les
                                CGU pour prendre connaissance des éventuelles mises à jour. <br />
                            </p>
                            <h5>Résiliation</h5>
                            <p>
                                Nous pouvons résilier votre compte et accès au site en cas de violation de ces CGU ou pour
                                toute autre raison à notre seule discrétion.
                                L’utilisateur peut également, pour des motifs légitimes, s’opposer au traitement des données le
                                concernant.<br />
                            </p>
                            <h5>Loi Applicable et Juridiction Compétente</h5>
                            <p>
                                Les présentes CGU sont régies par la loi française su et tout litige sera de la compétence
                                exclusive des tribunaux de la ville de résidence de l'utilisateur ou celle du siège social de
                                GAYOUMONEY LTD 71-75 Shelton Street, Covent Garden, Londres, BFP, WC2H 9JQ, RoyaumeUni
                            </p>
                    </section>
                </div>
            </div>
        </Container>
        <Footer />
    </>
)
}

export default MentionLegal