import React, { useEffect, useState } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import './EmployeDetails.css'
import Axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { selectedEmploye, removeSelectedEmploye } from '../../Redux/Actions/EmployesActions'
import { Navbar, Container, CreateAcountPopUp } from '../../components/index'
import { Footer } from '../../Sections/index'
import { ImUserTie } from 'react-icons/im'
import { BASE_URL } from '../../configApi/apiConfig'
const EmployeDetails = () => {
  const iconStyle = {
    color: 'rgba(0,0,0,0.3)',
    fontSize: '200',
    maxWidth: '200px',
    width: '100%',
  }
  const [emp, setEmp] = useState("")
  const user = useSelector((state) => state.user)
  const employe = useSelector((state) => state.employe)
  const { prenom_employe, specialite_employe, support_divinatoire_employe, image_employe, statut_employe, description_employe } = employe[0];
  const { pseudo_employe } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  window.scroll(0, 0)
  const fetchEmloyeDetail = async () => {
    const response = await Axios.get(`${BASE_URL}/api/ReturnEmploye/${pseudo_employe}/`)
      .then((response) => dispatch(selectedEmploye(response.data)))
      .catch((err) => console.log(err))
  }
  useEffect(() => {
    if (pseudo_employe && pseudo_employe !== ""){
      fetchEmloyeDetail()
      window.scroll(0, 0)
    }
    else {
      return () => {
        dispatch(removeSelectedEmploye())
      }
    }
  }, [pseudo_employe])
  const [loginUser, setLoginUser] = useState(false)
  useEffect(() => {
    const loggedInUser = localStorage.getItem("LoggedIn")
    if (loggedInUser !== null) {
      const found = JSON.parse(loggedInUser)
      setLoginUser(found)
    } else {
      setLoginUser(false)
    }
  }, [user.login])
  const verification = async (pseudo_employe) => {
    const pseudoUser = window.localStorage.getItem("pseudo");
    setEmp(pseudo_employe)
    if (pseudoUser) {
      try {
        const response = await Axios.get(`${BASE_URL}/api/ReturnUtilisateur/${pseudoUser}/`);
        if (response.data[0].payment) {
          const employeResponse = await Axios.get(`${BASE_URL}/api/ReturnEmploye/${emp}/`);
          console.log(employeResponse.data[0].nbr_utilisateurs);
          if (employeResponse.data[0].nbr_utilisateurs < 4) {
            const histo_utilisateur_not_found = await Axios.post(`${BASE_URL}/api/historique_utilisateur/`, {
              pseudo_employe: emp,
              pseudo: pseudoUser
            });
            console.log(histo_utilisateur_not_found.data)
            if(histo_utilisateur_not_found.data){
                await Axios.post(`${BASE_URL}/api/Createhistorique/`, {
                  pseudo_employe: emp,
                  pseudo: pseudoUser
                });
            }
            const conversationUtilisateurResponse = await Axios.post(`${BASE_URL}/api/conversation_utilisateur/`, {
                pseudo_employe: emp,
                pseudo: pseudoUser
              })
              if(conversationUtilisateurResponse.data){
                  await Axios.post(`${BASE_URL}/api/Createconversation/`, {
                  pseudo_employe: emp,
                  pseudo: pseudoUser
                });
                await Axios.post(`${BASE_URL}/api/number_utilisateur/`, {
                    pseudo_employe: emp
                  });
                  navigate('/messenger');
              }
             else {
                navigate('/messenger');
              }
            
          } else {
            const conversationUtilisateurResponse = await Axios.post(`${BASE_URL}/api/conversation_utilisateur/`, {
              pseudo_employe: emp,
              pseudo: pseudoUser
            });
            if (!conversationUtilisateurResponse.data) {
              console.log(conversationUtilisateurResponse.data);
              navigate('/messenger');
            } else {
              alert('Ce Employer Est Trop chargé');
            }
          }
        } else {
          navigate('/payment');
        }
      } catch (error) {
        // Handle error here
      }
    }
  };
  return (
    <>
      <Navbar />
      <Container>
        {Object.keys(employe).length === 0 ?
          (
            <div className='loading'>...Loading</div>
          )
          :
          (
            <div className='employe-details-container'>
              <div className='employe-item' key={pseudo_employe} onFocus={() => setEmp(pseudo_employe)}>
                <div className='employe-wrapper'>
                  <img className='employe-item-image-d' src={`${BASE_URL}${image_employe}`} />
                  <div className='employe-item-content'>
                    <h4 className='employe-item-name'>{prenom_employe}</h4>
                    <p className='employe-item-specialite'>Spécialité : {specialite_employe}</p>
                    <span className='employe-item-support'>Support divinatoire : {support_divinatoire_employe}</span>
                    {statut_employe ? <button className='employe-item-statut-btn-green-details'>Disponible</button> : <button className='employe-item-statut-btn-red-details'>indisponible</button>}
                    <Link to='/' className='employe-item-details-btn'>Liste Des Voyants</Link>
                    {loginUser ? <button onClick={() => verification(pseudo_employe)} className='chat'>Lancer Le Chat avec {prenom_employe}</button> : <CreateAcountPopUp title={`Lancer Le chat avec ${prenom_employe}`} />}
                  </div>
                  <ImUserTie style={iconStyle} />
                </div>
              </div>
              <div className='employe-detail-description'>
                <h4>{prenom_employe}</h4>
                <p>{description_employe}</p>
              </div>
            </div>
          )
        }
      </Container>
      <Footer />
    </>
  )
}

export default EmployeDetails